import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKalina as author } from 'data/authors';
import styled from 'styled-components';
import img from 'img/blog/user-frustration/frustration.png';
import img1 from 'img/blog/user-frustration/image1.jpg';
import img2 from 'img/blog/user-frustration/image2.jpg';
import img3 from 'img/blog/user-frustration/image3.jpg';
import img4 from 'img/blog/user-frustration/image4.jpg';
// import rageClickPhoto from "img/featurePhotos/rage-clicks.svg";
import topListPhoto from 'img/featurePhotos/toplistphoto.svg';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    color: #777;
    font-weight: 700;
    font-size: 1rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>
        The web can be a source of all kinds of emotions, ranging from pure happiness to sheer
        frustration. Of course, everyone wants to avoid the latter – and not without a reason.
        According to research conducted at{' '}
        <a
          href="https://www.livescience.com/1827-bad-memories-stick-good.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Boston College
        </a>
        , we tend to remember the negative things in more detail than the positive ones. Once
        something frustrating happens, it’s more likely to stick than the good things.{' '}
        <a
          href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/future-of-customer-experience.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          PWC’s report
        </a>{' '}
        reflects this dependence, as it claims that 32% of customers are likely to abandon the brand
        they loved after just one bad experience.
      </p>
      <p>How do you prevent frustration from happening? And what can you do once it arises?</p>
      <p>In this article, we’re going to cover the following topics:</p>
      <ul>
        <li>how to detect user frustration in the early stages</li>
        <li>what are the different kinds of user frustration</li>
        <li>how to find out why your users are frustrated</li>
        <li>what can you do to prevent and reduce frustration</li>
      </ul>
      <p>Keep reading to improve your user experience and make your visitors happier!</p>
      <br />
      <h2>What causes user frustration</h2>
      <h3>Errors</h3>
      <img
        src={img3}
        alt="errors"
        className="img-fluid justify-content-center mb-5 mt-3"
        title="User frustration"
        loading="lazy"
      />
      <p>
        According to a UX survey conducted by{' '}
        <a
          href="https://clutch.co/web-designers/resources/user-experience-top-websites-2018"
          target="_blank"
          rel="noopener noreferrer"
        >
          Clutch
        </a>
        , <b>unreliability</b> is one of the main causes of frustration when using a website. The
        same survey suggests that 63% of users (which is almost two-thirds) will abandon a website
        permanently if they find it unreliable.
      </p>
      <p>
        This means that if a user stumbles upon a broken link, an irrelevant CTA button or a 404
        error, they probably won’t be patient enough to stay and try again. Therefore, errors on
        your website are the burning issue that needs to be solved right away. They prevent the user
        from fulfilling their main goal – and why would they stay, if they can’t get what they want?
      </p>
      <h3>The tyranny of choice</h3>
      <img
        src={img2}
        alt="The-tyranny-of-choice"
        className="img-fluid justify-content-center mb-5 mt-3"
        title="Colors"
        loading="lazy"
      />
      <p>The more, the better – or is it?</p>

      <p>
        You might think that offering a bigger choice to the visitor will make them happier.
        Research by an American psychologist{' '}
        <a
          href="https://www.ted.com/talks/barry_schwartz_the_paradox_of_choice"
          target="_blank"
          rel="noopener noreferrer"
        >
          Barry Schwartz
        </a>{' '}
        suggests that having too much choice leads to anxiety and, you guessed it, frustration.{' '}
      </p>

      <p>
        This phenomenon can be explained through the main principles of cognitive psychology. No
        matter how trivial, every choice is a cognitive load. Our resources are limited and, if
        possible, we refrain from making too many decisions. If the user is faced with too many
        decisions, they’re quite likely to choose the easy way – <i>decision avoidance</i>. For you,
        it means they will just leave the website.{' '}
      </p>
      <p>
        That’s not the only explanation, though. Schwartz also claims that no matter what we choose,{' '}
        <a
          href="https://www.justinmind.com/blog/the-paradox-of-choice-ux-design/"
          target="_blank"
          rel="noopener noreferrer"
        >
          we won’t be entirely happy
        </a>{' '}
        – because what if there was a better alternative and we rejected it?
      </p>
      <p>
        So, how does it apply to web design and UX?{' '}
        <a href="https://eightshapes.com/dan-brown.html" target="_blank" rel="noopener noreferrer">
          Dan Brown
        </a>
        , an information architecture guru, came up with the <b>principle of choices</b> – the point
        is to give your users a certain degree of freedom, but not overwhelm them with options.
        Instead of confronting people with a lot of decisions at once, the user journey can be
        simplified or divided into smaller steps. One of the best ways to make that happen is{' '}
        <b>search filtering</b>, like in the case of most clothing retailers. Instead of browsing
        thousands of items, you can focus on little black dresses instead.
      </p>
      <h3>Page speed</h3>
      <img
        src={img1}
        alt="Page-speed"
        className="img-fluid justify-content-center mb-5 mt-3"
        title="Page speed"
        loading="lazy"
      />
      <p>
        In the age of everything fast, we want to access the information right away. How fast is
        right away, though?
      </p>
      <p>
        <a
          href="https://www.namogoo.com/blog/the-connection-between-page-load-speeds-and-user-experience/"
          target="_blank"
          rel="noopener noreferrer"
        >
          40% of users
        </a>{' '}
        will abandon your website if it takes more than 3 seconds to load. And that’s not all – the
        visitors are even more demanding when it comes to mobile experiences. According to{' '}
        <a
          href="https://www.thinkwithgoogle.com/intl/en-154/insights-inspiration/research-data/need-mobile-speed-how-mobile-latency-impacts-publisher-revenue/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google
        </a>{' '}
        itself, 53% are likely to leave a website if it’s loading for more than 3 seconds on a
        mobile device.
      </p>
      <p>
        So, is it alright if you manage below the magical 3-second benchmark and just leave it
        there?
      </p>
      <p>
        Well, not exactly. It’s more like <i>every little helps</i>.
      </p>
      <p>
        When it comes to companies like Amazon, even a 0.1 increase in page loading time results in
        a 1% decrease in sales. In terms of an e-commerce giant like that, we’re talking about a{' '}
        <a
          href="https://www.perficientdigital.com/insights/our-research/page-speed-guide"
          target="_blank"
          rel="noopener noreferrer"
        >
          $1.3 billion
        </a>{' '}
        annual loss. Even if you’re not running a huge international business, improving your
        website speed can be a quick win, too.
      </p>
      <h3>Misleading communication</h3>
      <img
        src={img4}
        alt="Misleading-communication"
        className="img-fluid justify-content-center mb-5 mt-3"
        title="Communication"
        loading="lazy"
      />
      <p>
        Your users won’t find what they’re looking for if they don’t know where to look for it –
        simple as that.
      </p>
      <p>
        Communication can be misleading on many levels. You might be using tech jargon and not see
        any problem with it, but your visitors still won’t understand what you’re trying to say.
        Reinventing the wheel usually isn’t working either – if a certain action, or a feature, is
        commonly known as “X”, most of the time it doesn’t make much sense to rename it. Instead of
        making your users curious, you’re more likely to confuse them instead.
      </p>
      <p>
        Most communication errors don’t lead to complete resignation, but they make the process
        unnecessarily complicated. When it comes to improving{' '}
        <a href="/blog/improving-customer-experience/" target="_blank" rel="noopener noreferrer">
          customer experience
        </a>
        , it’s a huge field for improvement.
      </p>
      <h2>Detecting user frustration </h2>
      <img
        src={topListPhoto}
        alt="Page-speed"
        className="img-fluid justify-content-center mb-5 mt-3"
        title="Detecting user frustration"
        loading="lazy"
      />
      <h3>Session replay</h3>
      <p>
        To find the sources of frustration, you need to see what the users are doing on your
        website. Session recording tools like{' '}
        <a href="https://livesession.io/" target="_blank" rel="noopener noreferrer">
          LiveSession
        </a>{' '}
        will help you get started. Certain on-screen behaviours are a clear indication that
        something is not going right. Some of the most prominent ones include <i>error clicks</i>{' '}
        and <i>rage clicks</i>.
      </p>
      <h3>Error clicks</h3>
      <p>
        Ever clicked on something that was supposed to be clickable and it wasn’t working? This is
        what we call an <b>error click</b>. Technically speaking, error clicks are detected when a
        JavaScript error occurs. And less technically speaking, this feature allows you to find out
        immediately when something is broken on your website.
      </p>
      <h3>Rage clicks</h3>
      <p>
        <b>Rage clicks</b>, on the other hand, are detected when the user clicks on the same feature
        very fast, multiple times in a row. This is a very clear indicator that they’re frustrated.
        It might happen when they’re trying to move to the next step, send a form or purchase
        something and the button is not working.
      </p>
      <p>
        If you would like to learn more, you might be interested in our{' '}
        <a
          href="https://livesession.io/blog/error-rage-clicks/"
          target="_blank"
          rel="noopener noreferrer"
        >
          in-depth article on error clicks and rage clicks
        </a>
        .
      </p>
      <h3>User surveys</h3>
      <p>
        Most of the time, when the visitor is frustrated, they won’t stick around for too long –
        hence they won’t fill any survey that pops up. This doesn’t mean you shouldn’t use surveys
        altogether, though! This method can be really helpful when it comes to collecting feedback
        from returning users. When someone’s loyal to your brand and they’ve been using your product
        for a long time, they’re very likely to have some interesting insights and suggestions.
      </p>
      <h2>How to reduce frustration</h2>
      <h3>Keep your website consistent</h3>
      <p>
        This requires a certain level of{' '}
        <a
          href="https://www.invisionapp.com/design-better/design-maturity-model/"
          target="_blank"
          rel="noopener noreferrer"
        >
          design maturity
        </a>{' '}
        , but it’s sure to pay off in the long run.
      </p>

      <p>
        If you’ve been working on your product for a while now, there were probably different people
        and different methods involved. When you run a{' '}
        <a
          href="https://usabilitygeek.com/ux-audit-beginners-guide/"
          target="_blank"
          rel="noopener noreferrer"
        >
          UX audit
        </a>{' '}
        and a{' '}
        <a
          href="https://www.quicksprout.com/content-audit/"
          target="_blank"
          rel="noopener noreferrer"
        >
          content audit
        </a>{' '}
        of your website, you’ll soon notice that not all pages are designed according to the same
        rules and some elements have different names across the website.
      </p>
      <p>
        Now, try looking at this state of things from the perspective of your user. Wouldn’t that
        feel confusing if one feature had different design and a different name, depending on how
        you reach it? You don’t always think about it when creating your digital product, yet your
        users are going to notice this at first glance – and they might find it incredibly
        misleading and frustrating.
      </p>
      <h3>Combine qualitative and quantitative analytics</h3>
      <p>
        Your bounce rates may tell you where to look, but they won’t tell you exactly why the users
        leave your website. Combining your quantitative data (e.g.{' '}
        <a
          href="https://livesession.io/help/google-analytics-integration/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics
        </a>
        ) with qualitative insights from LiveSession will help you understand when and why your
        users are getting frustrated.
      </p>
      <h2>Summary</h2>
      <p>
        The key to preventing user frustration and handling it effectively is{' '}
        <i>in-depth research and monitoring</i>. We highly encourage you to track user behavior and
        analyze it in order to find fields for improvement. Thanks to tools like LiveSession, you’ll
        be able to spot the most urgent issues immediately, too.{' '}
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Understanding user frustration',
  url: '/blog/user-frustration',
  description:
    'The web can be a source of all kinds of emotions, ranging from pure happiness to sheer frustration. Of course, everyone wants to avoid the latter – and not without a reason.',
  author,
  img,
  imgSocial: img,
  date: '2019-10-18',
  category: '',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Articles',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    descriptionSocial="The web can be a source of all kinds of emotions, ranging from pure happiness to sheer frustration. Of course, everyone wants to avoid the latter – and not without a reason."
  />
);
